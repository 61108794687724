// extracted by mini-css-extract-plugin
export var box = "Conflict-module--box--EsXSo";
export var button = "Conflict-module--button--xg8Ya";
export var buttonWrapper = "Conflict-module--buttonWrapper--NR0r5";
export var container = "Conflict-module--container--cWBC9";
export var done = "Conflict-module--done--7jmtx";
export var error = "Conflict-module--error--cWQGR";
export var errors = "Conflict-module--errors--3+XeY";
export var heading = "Conflict-module--heading--hGhcz";
export var headingSub = "Conflict-module--headingSub--gey5-";
export var label = "Conflict-module--label--7kAXX";
export var reason = "Conflict-module--reason--SJm59";
export var select = "Conflict-module--select--sOZXk";
export var textarea = "Conflict-module--textarea--3HZKV";