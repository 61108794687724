import React from "react";

import { Helmet } from "react-helmet";

import { MouseEvent } from "react";
import { RouteComponentProps } from "@reach/router";

import clsx from "clsx";

import { createRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

import Box from "@js/component/Box";
import Button from "@js/component/Button";
import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import Main from "@js/component/Main";
import Textarea from "@js/component/Textarea";

import * as classNames from "@css/component/template/Conflict.module.scss";

/**
 * @type ConflictProps
 */
type ConflictProps = RouteComponentProps & {
    hash?: string;
    status?: string;
};

/**
 * @const Conflict
 */
const Conflict = (props: ConflictProps) => {
    const {
        hash,
        status
    } = props;

    const formRef = createRef<HTMLFormElement>();

    const [conflict, setConflict] = useState<{ advisorNote?: string; reason: { code: string; name: string; }; }>();
    const [isFetchingConflict, setIsFetchingConflict] = useState(true);

    const [isDone, setIsDone] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});

    const [message, setMessage] = useState("");

    const fetchConflict = async () => {
        setIsFetchingConflict(true);

        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/request/conflict/${ hash }`);

        const data = await response.json();

        setIsFetchingConflict(false);

        setConflict(data);
    };

    const onSubmitButtonClick = (event: MouseEvent) => {
        event.preventDefault();

        if (formRef.current?.reportValidity()) {
            void send();
        }
    };

    const send = async () => {
        setIsSending(true);

        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/request/conflict/${ hash }`, {
            body: JSON.stringify({
                buildingOwnerAgreed: !(status == "disagree"),
                message: message
            }),
            method: "PUT"
        });

        const data = await response.json();

        setIsSending(false);

        if (data.status == "failed") {
            setErrors(data.errors);
        }
        else {
            setIsDone(true);
        }
    };

    useEffect(() => {
        if (!(status == "disagree")) {
            void send();
        }
    }, []);

    useEffect(() => {
        setIsDone(false);

        void fetchConflict();
    }, []);

    return (
        <Main>
            <Helmet>
                <title>Aanvraag afgewezen</title>
            </Helmet>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="large">
                    Je aanvraag is afgewezen
                </Heading>
                <Box className={ classNames.box } element="section">
                    { status == "disagree" && (
                        <>
                            <Heading className={ classNames.headingSub } variant="medium">
                                { !(isDone) && "Geef aan waarom je het niet eens bent met de energielabel-adviseur die je aanvraag heeft afgewezen" }
                                { isDone && "Je reactie is ingediend!" }
                            </Heading>
                            { !(isFetchingConflict) && (
                                <p>
                                    <strong className={ classNames.reason }>Je aanvraag is om de volgende reden afgewezen:</strong><br />
                                    { conflict?.reason.name }<br />
                                    <i>{ conflict?.advisorNote }</i>
                                </p>
                            ) }
                            { !(isDone) &&
                                <form ref={ formRef }>
                                    <ul className={ classNames.errors }>
                                        { errors["#"] &&
                                        errors["#"].map((error, index) => (
                                            <li className={ classNames.error } key={ index }>{ error }</li>
                                        )) }
                                    </ul>
                                    <ul className={ classNames.errors }>
                                        { errors.review &&
                                        errors.review.map((error, index) => (
                                            <li className={ classNames.error } key={ index }>{ error }</li>
                                        )) }
                                    </ul>
                                    <label className={ classNames.label } htmlFor="message">Waarom ben je het hier niet mee eens?</label>
                                    <Textarea
                                        className={ clsx(classNames.textarea, {
                                            [classNames.error]: errors.message
                                        }) }
                                        id="message"
                                        name="message"
                                        onChange={ (event) => setMessage(event.currentTarget.value) }
                                        placeholder="Begin hier te typen"
                                        // required
                                        value={ message }
                                    />
                                    <ul className={ classNames.errors }>
                                        { errors.reviewMessage &&
                                        errors.reviewMessage.map((error, index) => (
                                            <li className={ classNames.error } key={ index }>{ error }</li>
                                        )) }
                                    </ul>
                                    <div className={ classNames.buttonWrapper }>
                                        <Button
                                            className={ classNames.button }
                                            disabled={ isSending }
                                            onClick={ onSubmitButtonClick }
                                        >
                                            { !(isSending) && "Indienen" }
                                            { isSending && "Bezig met indienen..." }
                                        </Button>
                                    </div>
                                </form>
                            }
                            { isDone &&
                                <>
                                    <div className={ classNames.done }>
                                        Dank voor het aangeven dat je het oneens bent met deze afwijzing.
                                    </div>
                                    <LinkButton className={ classNames.button } to="/">Ga terug naar home</LinkButton>
                                </>
                            }
                        </>
                    ) }
                    { !(status == "disagree") && (
                        <>
                            <Heading className={ classNames.headingSub } variant="medium">
                                Bedankt voor de terugkoppeling!
                            </Heading>
                            <div className={ classNames.done }>
                                Dank voor het aangeven dat je het eens bent met deze afwijzing.
                            </div>
                            <LinkButton className={ classNames.button } to="/">Ga terug naar home</LinkButton>
                        </>
                    ) }
                </Box>
            </div>
        </Main>
    );
};

export default Conflict;
